import React from "react";
import { Link } from "gatsby";
import { ErrorLayout as Layout } from "../components/layout";
import { Button } from "../components/base";

const adviserExternalUrl = process.env.ADVISER_EXTERNAL_URL;

export default function ErrorPage({ title = "Something went wrong" }) {
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center">
        <div className="font-just-script text-lg md:text-3xl leading-close mt-6">
          <h1>{title}</h1>
        </div>
        <div className="w-full md:w-auto md:mt-12">
          <div className="mt-6">
            <Button component={Link} to="/" outlined className="w-full">
              Create New KFI
            </Button>
          </div>
          <div className="mt-6 md:mx-14 confirmationLink">
            <a
              href={adviserExternalUrl}
              className="link no-underline block text-center w-full md:w-auto font-normal normal-case text-base leading-normal"
            >
              Back to Just Adviser
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
